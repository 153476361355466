export default [
  {
    id: 1,
    name: 'Euro',
    code: 'EUR',
    sign: '\u20ac',
    space: true,
    after_amount: true,
  },
  {
    id: 2,
    name: 'Swiss Franc',
    code: 'CHF',
    sign: 'CHF',
    space: true,
    after_amount: true,
  },
  {
    id: 3,
    name: 'Hungarian forint',
    code: 'HUF',
    sign: 'Ft',
    space: true,
    after_amount: true,
  },
  {
    id: 4,
    name: 'Thai baht',
    code: 'THB',
    sign: '\u0e3f',
    space: true,
    after_amount: false,
  },
  {
    id: 5,
    name: 'Malaysian ringgit',
    code: 'MYR',
    sign: 'MYR',
    space: true,
    after_amount: true,
  },
  {
    id: 6,
    name: 'Philippine piso',
    code: 'PHP',
    sign: 'PHP',
    space: true,
    after_amount: true,
  },
  {
    id: 7,
    name: 'United States dollar',
    code: 'USD',
    sign: '$',
    space: true,
    after_amount: false,
  },
  {
    id: 8,
    name: 'Japanese yen',
    code: 'JPY',
    sign: '\u00a5',
    space: true,
    after_amount: false,
  },
  {
    id: 9,
    name: 'Pound sterling',
    code: 'GBP',
    sign: '\u00a3',
    space: true,
    after_amount: false,
  },
  {
    id: 10,
    name: 'Bitcoin',
    code: 'BTC',
    sign: 'BTC',
    space: true,
    after_amount: true,
  },
  {
    id: 11,
    name: 'United Arab Emirates dirham',
    code: 'AED',
    sign: 'AED',
    space: true,
    after_amount: true,
  },
  {
    id: 12,
    name: 'Afghan afghani',
    code: 'AFN',
    sign: 'AFN',
    space: true,
    after_amount: true,
  },
  {
    id: 13,
    name: 'Albanian lek',
    code: 'ALL',
    sign: 'ALL',
    space: true,
    after_amount: true,
  },
  {
    id: 14,
    name: 'Armenian dram',
    code: 'AMD',
    sign: 'AMD',
    space: true,
    after_amount: true,
  },
  {
    id: 15,
    name: 'Netherlands Antillean guilder',
    code: 'ANG',
    sign: 'ANG',
    space: true,
    after_amount: true,
  },
  {
    id: 16,
    name: 'Angolan kwanza',
    code: 'AOA',
    sign: 'AOA',
    space: true,
    after_amount: true,
  },
  {
    id: 17,
    name: 'Argentine peso',
    code: 'ARS',
    sign: 'ARS',
    space: true,
    after_amount: true,
  },
  {
    id: 18,
    name: 'Australian dollar',
    code: 'AUD',
    sign: 'AUD',
    space: true,
    after_amount: true,
  },
  {
    id: 19,
    name: 'Aruban florin',
    code: 'AWG',
    sign: 'AWG',
    space: true,
    after_amount: true,
  },
  {
    id: 20,
    name: 'Azerbaijani manat',
    code: 'AZN',
    sign: 'AZN',
    space: true,
    after_amount: true,
  },
  {
    id: 21,
    name: 'Bosnia and Herzegovina convertible mark',
    code: 'BAM',
    sign: 'BAM',
    space: true,
    after_amount: true,
  },
  {
    id: 22,
    name: 'Barbados dollar',
    code: 'BBD',
    sign: 'BBD',
    space: true,
    after_amount: true,
  },
  {
    id: 23,
    name: 'Bangladeshi taka',
    code: 'BDT',
    sign: 'BDT',
    space: true,
    after_amount: true,
  },
  {
    id: 24,
    name: 'Bulgarian lev',
    code: 'BGN',
    sign: 'BGN',
    space: true,
    after_amount: true,
  },
  {
    id: 25,
    name: 'Bahraini dinar',
    code: 'BHD',
    sign: 'BHD',
    space: true,
    after_amount: true,
  },
  {
    id: 26,
    name: 'Burundian franc',
    code: 'BIF',
    sign: 'BIF',
    space: true,
    after_amount: true,
  },
  {
    id: 27,
    name: 'Bermudian dollar',
    code: 'BMD',
    sign: 'BMD',
    space: true,
    after_amount: true,
  },
  {
    id: 28,
    name: 'Brunei dollar',
    code: 'BND',
    sign: 'BND',
    space: true,
    after_amount: true,
  },
  {
    id: 29,
    name: 'Boliviano',
    code: 'BOB',
    sign: 'BOB',
    space: true,
    after_amount: true,
  },
  {
    id: 30,
    name: 'Bolivian Mvdol (funds code)',
    code: 'BOV',
    sign: 'BOV',
    space: true,
    after_amount: true,
  },
  {
    id: 31,
    name: 'Brazilian real',
    code: 'BRL',
    sign: 'BRL',
    space: true,
    after_amount: true,
  },
  {
    id: 32,
    name: 'Bahamian dollar',
    code: 'BSD',
    sign: 'BSD',
    space: true,
    after_amount: true,
  },
  {
    id: 33,
    name: 'Bhutanese ngultrum',
    code: 'BTN',
    sign: 'BTN',
    space: true,
    after_amount: true,
  },
  {
    id: 34,
    name: 'Botswana pula',
    code: 'BWP',
    sign: 'BWP',
    space: true,
    after_amount: true,
  },
  {
    id: 35,
    name: 'Belarusian ruble',
    code: 'BYN',
    sign: 'BYN',
    space: true,
    after_amount: true,
  },
  {
    id: 36,
    name: 'Belize dollar',
    code: 'BZD',
    sign: 'BZD',
    space: true,
    after_amount: true,
  },
  {
    id: 37,
    name: 'Canadian dollar',
    code: 'CAD',
    sign: 'CAD',
    space: true,
    after_amount: true,
  },
  {
    id: 38,
    name: 'Congolese franc',
    code: 'CDF',
    sign: 'CDF',
    space: true,
    after_amount: true,
  },
  {
    id: 39,
    name: 'WIR Euro (complementary currency)',
    code: 'CHE',
    sign: 'CHE',
    space: true,
    after_amount: true,
  },
  {
    id: 40,
    name: 'WIR Franc (complementary currency)',
    code: 'CHW',
    sign: 'CHW',
    space: true,
    after_amount: true,
  },
  {
    id: 41,
    name: 'Unidad de Fomento (funds code)',
    code: 'CLF',
    sign: 'CLF',
    space: true,
    after_amount: true,
  },
  {
    id: 42,
    name: 'Chilean peso',
    code: 'CLP',
    sign: 'CLP',
    space: true,
    after_amount: true,
  },
  {
    id: 43,
    name: 'Chinese yuan',
    code: 'CNY',
    sign: 'CNY',
    space: true,
    after_amount: true,
  },
  {
    id: 44,
    name: 'Colombian peso',
    code: 'COP',
    sign: 'COP',
    space: true,
    after_amount: true,
  },
  {
    id: 45,
    name: 'Unidad de Valor Real (UVR) (funds code)[7]',
    code: 'COU',
    sign: 'COU',
    space: true,
    after_amount: true,
  },
  {
    id: 46,
    name: 'Costa Rican colon',
    code: 'CRC',
    sign: 'CRC',
    space: true,
    after_amount: true,
  },
  {
    id: 47,
    name: 'Cuban convertible peso',
    code: 'CUC',
    sign: 'CUC',
    space: true,
    after_amount: true,
  },
  {
    id: 48,
    name: 'Cuban peso',
    code: 'CUP',
    sign: 'CUP',
    space: true,
    after_amount: true,
  },
  {
    id: 49,
    name: 'Cape Verde escudo',
    code: 'CVE',
    sign: 'CVE',
    space: true,
    after_amount: true,
  },
  {
    id: 50,
    name: 'Czech koruna',
    code: 'CZK',
    sign: 'CZK',
    space: true,
    after_amount: true,
  },
  {
    id: 51,
    name: 'Djiboutian franc',
    code: 'DJF',
    sign: 'DJF',
    space: true,
    after_amount: true,
  },
  {
    id: 52,
    name: 'Danish krone',
    code: 'DKK',
    sign: 'DKK',
    space: true,
    after_amount: true,
  },
  {
    id: 53,
    name: 'Dominican peso',
    code: 'DOP',
    sign: 'DOP',
    space: true,
    after_amount: true,
  },
  {
    id: 54,
    name: 'Algerian dinar',
    code: 'DZD',
    sign: 'DZD',
    space: true,
    after_amount: true,
  },
  {
    id: 55,
    name: 'Egyptian pound',
    code: 'EGP',
    sign: 'EGP',
    space: true,
    after_amount: true,
  },
  {
    id: 56,
    name: 'Eritrean nakfa',
    code: 'ERN',
    sign: 'ERN',
    space: true,
    after_amount: true,
  },
  {
    id: 57,
    name: 'Ethiopian birr',
    code: 'ETB',
    sign: 'ETB',
    space: true,
    after_amount: true,
  },
  {
    id: 58,
    name: 'Fiji dollar',
    code: 'FJD',
    sign: 'FJD',
    space: true,
    after_amount: true,
  },
  {
    id: 59,
    name: 'Falkland Islands pound',
    code: 'FKP',
    sign: 'FKP',
    space: true,
    after_amount: true,
  },
  {
    id: 61,
    name: 'Georgian lari',
    code: 'GEL',
    sign: 'GEL',
    space: true,
    after_amount: true,
  },
  {
    id: 62,
    name: 'Ghanaian cedi',
    code: 'GHS',
    sign: 'GHS',
    space: true,
    after_amount: true,
  },
  {
    id: 63,
    name: 'Gibraltar pound',
    code: 'GIP',
    sign: 'GIP',
    space: true,
    after_amount: true,
  },
  {
    id: 64,
    name: 'Gambian dalasi',
    code: 'GMD',
    sign: 'GMD',
    space: true,
    after_amount: true,
  },
  {
    id: 65,
    name: 'Guinean franc',
    code: 'GNF',
    sign: 'GNF',
    space: true,
    after_amount: true,
  },
  {
    id: 66,
    name: 'Guatemalan quetzal',
    code: 'GTQ',
    sign: 'GTQ',
    space: true,
    after_amount: true,
  },
  {
    id: 67,
    name: 'Guyanese dollar',
    code: 'GYD',
    sign: 'GYD',
    space: true,
    after_amount: true,
  },
  {
    id: 68,
    name: 'Hong Kong dollar',
    code: 'HKD',
    sign: 'HKD',
    space: true,
    after_amount: true,
  },
  {
    id: 69,
    name: 'Honduran lempira',
    code: 'HNL',
    sign: 'HNL',
    space: true,
    after_amount: true,
  },
  {
    id: 70,
    name: 'Croatian kuna',
    code: 'HRK',
    sign: 'HRK',
    space: true,
    after_amount: true,
  },
  {
    id: 71,
    name: 'Haitian gourde',
    code: 'HTG',
    sign: 'HTG',
    space: true,
    after_amount: true,
  },
  {
    id: 72,
    name: 'Indonesian rupiah',
    code: 'IDR',
    sign: 'IDR',
    space: true,
    after_amount: true,
  },
  {
    id: 73,
    name: 'Israeli new shekel',
    code: 'ILS',
    sign: 'ILS',
    space: true,
    after_amount: true,
  },
  {
    id: 74,
    name: 'Indian rupee',
    code: 'INR',
    sign: 'INR',
    space: true,
    after_amount: true,
  },
  {
    id: 75,
    name: 'Iraqi dinar',
    code: 'IQD',
    sign: 'IQD',
    space: true,
    after_amount: true,
  },
  {
    id: 76,
    name: 'Iranian rial',
    code: 'IRR',
    sign: 'IRR',
    space: true,
    after_amount: true,
  },
  {
    id: 77,
    name: 'Icelandic kr\u00f3na',
    code: 'ISK',
    sign: 'ISK',
    space: true,
    after_amount: true,
  },
  {
    id: 78,
    name: 'Jamaican dollar',
    code: 'JMD',
    sign: 'JMD',
    space: true,
    after_amount: true,
  },
  {
    id: 79,
    name: 'Jordanian dinar',
    code: 'JOD',
    sign: 'JOD',
    space: true,
    after_amount: true,
  },
  {
    id: 80,
    name: 'Kenyan shilling',
    code: 'KES',
    sign: 'KES',
    space: true,
    after_amount: true,
  },
  {
    id: 81,
    name: 'Kyrgyzstani som',
    code: 'KGS',
    sign: 'KGS',
    space: true,
    after_amount: true,
  },
  {
    id: 82,
    name: 'Cambodian riel',
    code: 'KHR',
    sign: 'KHR',
    space: true,
    after_amount: true,
  },
  {
    id: 83,
    name: 'Comoro franc',
    code: 'KMF',
    sign: 'KMF',
    space: true,
    after_amount: true,
  },
  {
    id: 84,
    name: 'North Korean won',
    code: 'KPW',
    sign: 'KPW',
    space: true,
    after_amount: true,
  },
  {
    id: 85,
    name: 'South Korean won',
    code: 'KRW',
    sign: 'KRW',
    space: true,
    after_amount: true,
  },
  {
    id: 86,
    name: 'Kuwaiti dinar',
    code: 'KWD',
    sign: 'KWD',
    space: true,
    after_amount: true,
  },
  {
    id: 87,
    name: 'Cayman Islands dollar',
    code: 'KYD',
    sign: 'KYD',
    space: true,
    after_amount: true,
  },
  {
    id: 88,
    name: 'Kazakhstani tenge',
    code: 'KZT',
    sign: 'KZT',
    space: true,
    after_amount: true,
  },
  {
    id: 89,
    name: 'Lao kip',
    code: 'LAK',
    sign: 'LAK',
    space: true,
    after_amount: true,
  },
  {
    id: 90,
    name: 'Lebanese pound',
    code: 'LBP',
    sign: 'LBP',
    space: true,
    after_amount: true,
  },
  {
    id: 91,
    name: 'Sri Lankan rupee',
    code: 'LKR',
    sign: 'LKR',
    space: true,
    after_amount: true,
  },
  {
    id: 92,
    name: 'Liberian dollar',
    code: 'LRD',
    sign: 'LRD',
    space: true,
    after_amount: true,
  },
  {
    id: 93,
    name: 'Lesotho loti',
    code: 'LSL',
    sign: 'LSL',
    space: true,
    after_amount: true,
  },
  {
    id: 94,
    name: 'Libyan dinar',
    code: 'LYD',
    sign: 'LYD',
    space: true,
    after_amount: true,
  },
  {
    id: 95,
    name: 'Moroccan dirham',
    code: 'MAD',
    sign: 'MAD',
    space: true,
    after_amount: true,
  },
  {
    id: 96,
    name: 'Moldovan leu',
    code: 'MDL',
    sign: 'MDL',
    space: true,
    after_amount: true,
  },
  {
    id: 97,
    name: 'Malagasy ariary',
    code: 'MGA',
    sign: 'MGA',
    space: true,
    after_amount: true,
  },
  {
    id: 98,
    name: 'Macedonian denar',
    code: 'MKD',
    sign: 'MKD',
    space: true,
    after_amount: true,
  },
  {
    id: 99,
    name: 'Myanmar kyat',
    code: 'MMK',
    sign: 'MMK',
    space: true,
    after_amount: true,
  },
  {
    id: 100,
    name: 'Mongolian t\u00f6gr\u00f6g',
    code: 'MNT',
    sign: 'MNT',
    space: true,
    after_amount: true,
  },
  {
    id: 101,
    name: 'Macanese pataca',
    code: 'MOP',
    sign: 'MOP',
    space: true,
    after_amount: true,
  },
  {
    id: 102,
    name: 'Mauritanian ouguiya',
    code: 'MRO',
    sign: 'MRO',
    space: true,
    after_amount: true,
  },
  {
    id: 103,
    name: 'Mauritian rupee',
    code: 'MUR',
    sign: 'MUR',
    space: true,
    after_amount: true,
  },
  {
    id: 104,
    name: 'Maldivian rufiyaa',
    code: 'MVR',
    sign: 'MVR',
    space: true,
    after_amount: true,
  },
  {
    id: 105,
    name: 'Malawian kwacha',
    code: 'MWK',
    sign: 'MWK',
    space: true,
    after_amount: true,
  },
  {
    id: 106,
    name: 'Mexican peso',
    code: 'MXN',
    sign: 'MXN',
    space: true,
    after_amount: true,
  },
  {
    id: 107,
    name: 'Mexican Unidad de Inversion (UDI) (funds code)',
    code: 'MXV',
    sign: 'MXV',
    space: true,
    after_amount: true,
  },
  {
    id: 108,
    name: 'Mozambican metical',
    code: 'MZN',
    sign: 'MZN',
    space: true,
    after_amount: true,
  },
  {
    id: 109,
    name: 'Namibian dollar',
    code: 'NAD',
    sign: 'NAD',
    space: true,
    after_amount: true,
  },
  {
    id: 110,
    name: 'Nigerian naira',
    code: 'NGN',
    sign: 'NGN',
    space: true,
    after_amount: true,
  },
  {
    id: 111,
    name: 'Nicaraguan c\u00f3rdoba',
    code: 'NIO',
    sign: 'NIO',
    space: true,
    after_amount: true,
  },
  {
    id: 112,
    name: 'Norwegian krone',
    code: 'NOK',
    sign: 'NOK',
    space: true,
    after_amount: true,
  },
  {
    id: 113,
    name: 'Nepalese rupee',
    code: 'NPR',
    sign: 'NPR',
    space: true,
    after_amount: true,
  },
  {
    id: 114,
    name: 'New Zealand dollar',
    code: 'NZD',
    sign: 'NZD',
    space: true,
    after_amount: true,
  },
  {
    id: 115,
    name: 'Omani rial',
    code: 'OMR',
    sign: 'OMR',
    space: true,
    after_amount: true,
  },
  {
    id: 116,
    name: 'Panamanian balboa',
    code: 'PAB',
    sign: 'PAB',
    space: true,
    after_amount: true,
  },
  {
    id: 117,
    name: 'Peruvian Sol',
    code: 'PEN',
    sign: 'PEN',
    space: true,
    after_amount: true,
  },
  {
    id: 118,
    name: 'Papua New Guinean kina',
    code: 'PGK',
    sign: 'PGK',
    space: true,
    after_amount: true,
  },
  {
    id: 119,
    name: 'Pakistani rupee',
    code: 'PKR',
    sign: 'PKR',
    space: true,
    after_amount: true,
  },
  {
    id: 120,
    name: 'Polish z\u0142oty',
    code: 'PLN',
    sign: 'PLN',
    space: true,
    after_amount: true,
  },
  {
    id: 121,
    name: 'Paraguayan guaran\u00ed',
    code: 'PYG',
    sign: 'PYG',
    space: true,
    after_amount: true,
  },
  {
    id: 122,
    name: 'Qatari riyal',
    code: 'QAR',
    sign: 'QAR',
    space: true,
    after_amount: true,
  },
  {
    id: 123,
    name: 'Romanian leu',
    code: 'RON',
    sign: 'RON',
    space: true,
    after_amount: true,
  },
  {
    id: 124,
    name: 'Serbian dinar',
    code: 'RSD',
    sign: 'RSD',
    space: true,
    after_amount: true,
  },
  {
    id: 125,
    name: 'Russian ruble',
    code: 'RUB',
    sign: 'RUB',
    space: true,
    after_amount: true,
  },
  {
    id: 126,
    name: 'Rwandan franc',
    code: 'RWF',
    sign: 'RWF',
    space: true,
    after_amount: true,
  },
  {
    id: 127,
    name: 'Saudi riyal',
    code: 'SAR',
    sign: 'SAR',
    space: true,
    after_amount: true,
  },
  {
    id: 128,
    name: 'Solomon Islands dollar',
    code: 'SBD',
    sign: 'SBD',
    space: true,
    after_amount: true,
  },
  {
    id: 129,
    name: 'Seychelles rupee',
    code: 'SCR',
    sign: 'SCR',
    space: true,
    after_amount: true,
  },
  {
    id: 130,
    name: 'Sudanese pound',
    code: 'SDG',
    sign: 'SDG',
    space: true,
    after_amount: true,
  },
  {
    id: 131,
    name: 'Swedish krona/kronor',
    code: 'SEK',
    sign: 'SEK',
    space: true,
    after_amount: true,
  },
  {
    id: 132,
    name: 'Singapore dollar',
    code: 'SGD',
    sign: 'SGD',
    space: true,
    after_amount: true,
  },
  {
    id: 133,
    name: 'Saint Helena pound',
    code: 'SHP',
    sign: 'SHP',
    space: true,
    after_amount: true,
  },
  {
    id: 134,
    name: 'Sierra Leonean leone',
    code: 'SLL',
    sign: 'SLL',
    space: true,
    after_amount: true,
  },
  {
    id: 135,
    name: 'Somali shilling',
    code: 'SOS',
    sign: 'SOS',
    space: true,
    after_amount: true,
  },
  {
    id: 136,
    name: 'Surinamese dollar',
    code: 'SRD',
    sign: 'SRD',
    space: true,
    after_amount: true,
  },
  {
    id: 137,
    name: 'South Sudanese pound',
    code: 'SSP',
    sign: 'SSP',
    space: true,
    after_amount: true,
  },
  {
    id: 138,
    name: 'S\u00e3o Tom\u00e9 and Pr\u00edncipe dobra',
    code: 'STD',
    sign: 'STD',
    space: true,
    after_amount: true,
  },
  {
    id: 139,
    name: 'Salvadoran col\u00f3n',
    code: 'SVC',
    sign: 'SVC',
    space: true,
    after_amount: true,
  },
  {
    id: 140,
    name: 'Syrian pound',
    code: 'SYP',
    sign: 'SYP',
    space: true,
    after_amount: true,
  },
  {
    id: 141,
    name: 'Swazi lilangeni',
    code: 'SZL',
    sign: 'SZL',
    space: true,
    after_amount: true,
  },
  {
    id: 142,
    name: 'Tajikistani somoni',
    code: 'TJS',
    sign: 'TJS',
    space: true,
    after_amount: true,
  },
  {
    id: 143,
    name: 'Turkmenistan manat',
    code: 'TMT',
    sign: 'TMT',
    space: true,
    after_amount: true,
  },
  {
    id: 144,
    name: 'Tunisian dinar',
    code: 'TND',
    sign: 'TND',
    space: true,
    after_amount: true,
  },
  {
    id: 145,
    name: 'Tongan pa\u02bbanga',
    code: 'TOP',
    sign: 'TOP',
    space: true,
    after_amount: true,
  },
  {
    id: 146,
    name: 'Turkish lira',
    code: 'TRY',
    sign: 'TRY',
    space: true,
    after_amount: true,
  },
  {
    id: 147,
    name: 'Trinidad and Tobago dollar',
    code: 'TTD',
    sign: 'TTD',
    space: true,
    after_amount: true,
  },
  {
    id: 148,
    name: 'New Taiwan dollar',
    code: 'TWD',
    sign: 'TWD',
    space: true,
    after_amount: true,
  },
  {
    id: 149,
    name: 'Tanzanian shilling',
    code: 'TZS',
    sign: 'TZS',
    space: true,
    after_amount: true,
  },
  {
    id: 150,
    name: 'Ukrainian hryvnia',
    code: 'UAH',
    sign: 'UAH',
    space: true,
    after_amount: true,
  },
  {
    id: 151,
    name: 'Ugandan shilling',
    code: 'UGX',
    sign: 'UGX',
    space: true,
    after_amount: true,
  },
  {
    id: 152,
    name: 'United States dollar (next day) (funds code)',
    code: 'USN',
    sign: 'USN',
    space: true,
    after_amount: true,
  },
  {
    id: 153,
    name: 'Uruguay Peso en Unidades Indexadas (URUIURUI) (funds code)',
    code: 'UYI',
    sign: 'UYI',
    space: true,
    after_amount: true,
  },
  {
    id: 154,
    name: 'Uruguayan peso',
    code: 'UYU',
    sign: 'UYU',
    space: true,
    after_amount: true,
  },
  {
    id: 155,
    name: 'Uzbekistan som',
    code: 'UZS',
    sign: 'UZS',
    space: true,
    after_amount: true,
  },
  {
    id: 156,
    name: 'Venezuelan bol\u00edvar',
    code: 'VEF',
    sign: 'VEF',
    space: true,
    after_amount: true,
  },
  {
    id: 157,
    name: 'Vietnamese \u0111\u1ed3ng',
    code: 'VND',
    sign: 'VND',
    space: true,
    after_amount: true,
  },
  {
    id: 158,
    name: 'Vanuatu vatu',
    code: 'VUV',
    sign: 'VUV',
    space: true,
    after_amount: true,
  },
  {
    id: 159,
    name: 'Samoan tala',
    code: 'WST',
    sign: 'WST',
    space: true,
    after_amount: true,
  },
  {
    id: 160,
    name: 'CFA franc BEAC',
    code: 'XAF',
    sign: 'XAF',
    space: true,
    after_amount: true,
  },
  {
    id: 161,
    name: 'East Caribbean dollar',
    code: 'XCD',
    sign: 'XCD',
    space: true,
    after_amount: true,
  },
  {
    id: 162,
    name: 'CFA franc BCEAO',
    code: 'XOF',
    sign: 'XOF',
    space: true,
    after_amount: true,
  },
  {
    id: 163,
    name: 'CFP franc (franc Pacifique)    ',
    code: 'XPF',
    sign: 'XPF',
    space: true,
    after_amount: true,
  },
  {
    id: 164,
    name: 'Yemeni rial',
    code: 'YER',
    sign: 'YER',
    space: true,
    after_amount: true,
  },
  {
    id: 165,
    name: 'South African rand',
    code: 'ZAR',
    sign: 'ZAR',
    space: true,
    after_amount: true,
  },
  {
    id: 166,
    name: 'Zambian kwacha',
    code: 'ZMW',
    sign: 'ZMW',
    space: true,
    after_amount: true,
  },
  {
    id: 167,
    name: 'Zimbabwean dollar A/10',
    code: 'ZWL',
    sign: 'ZWL',
    space: true,
    after_amount: true,
  },
  {
    id: 168,
    name: 'Ethereum',
    code: 'ETH',
    sign: 'ETH',
    space: true,
    after_amount: true,
  },
  {
    id: 169,
    name: 'Dash',
    code: 'DASH',
    sign: 'DASH',
    space: true,
    after_amount: true,
  },
  {
    id: 170,
    name: 'Bitcoin Cash',
    code: 'BTH',
    sign: 'BTH',
    space: true,
    after_amount: true,
  },
  {
    id: 171,
    name: 'Stellar Lumen',
    code: 'XLM',
    sign: 'XLM',
    space: true,
    after_amount: true,
  },
  {
    id: 172,
    name: 'Zcash',
    code: 'ZEC',
    sign: 'ZEC',
    space: true,
    after_amount: true,
  },
  {
    id: 173,
    name: 'Ripple',
    code: 'XRP',
    sign: 'XRP',
    space: true,
    after_amount: true,
  },
  {
    id: 174,
    name: 'IOTA',
    code: 'IOT',
    sign: 'IOT',
    space: true,
    after_amount: true,
  },
  {
    id: 175,
    name: 'Bitcoin Gold',
    code: 'BTG',
    sign: 'BTG',
    space: true,
    after_amount: true,
  },
  {
    id: 176,
    name: 'Neo',
    code: 'NEO',
    sign: 'NEO',
    space: true,
    after_amount: true,
  },
  {
    id: 177,
    name: 'Ethereum Classic',
    code: 'ETC',
    sign: 'ETC',
    space: true,
    after_amount: true,
  },
  {
    id: 178,
    name: 'Litecoin',
    code: 'LTC',
    sign: 'LTC',
    space: true,
    after_amount: true,
  },
  {
    id: 179,
    name: 'OmiseGO',
    code: 'OMG',
    sign: 'OMG',
    space: true,
    after_amount: true,
  },
];
