import axios from 'axios';
import Cookies from 'universal-cookie';
const URL = process.env.API_URL;
const PATH = '/auth';
const url = `${URL}${PATH}`;

export const loginUser = (email, password) => {
  const data = {
    action: 'login',
    email,
    password,
  };
  return axios.put(url, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const signupUser = (password, email, firstName, lastName, newsletter) => {
  const data = {
    action: 'signup',
    password,
    email,
    firstName,
    lastName,
    profile: "'auto_sync': { BOOL: true }",
    isSubscribedToNewsletter: newsletter,
  };
  return axios.put(url, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const deleteUserAccount = email => {
  const cookies = new Cookies();
  const token = cookies.get('jwt_token');
  return axios.delete(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params: {
      email,
    },
  });
};

export const forgotPassword = (email, tempPassword, newPassword) => {
  return axios.put(
    url,
    {
      action: 'forgot-password',
      email,
      temp_password: tempPassword,
      password: newPassword,
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );
};

export const resetPassword = email => {
  return axios.post(
    `${URL}/reset-password`,
    {},
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: {
        email,
      },
    },
  );
};