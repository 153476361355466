import { DB_NAME, DB_VERSION } from './constants';

export class Storage {
  constructor() {}

  connectIndexedDB() {
    return new Promise(function (resolve, reject) {
      try {
        let request = indexedDB.open(DB_NAME, DB_VERSION);
        request.onupgradeneeded = function (event) {
          let connection = event.target.result;

          // Purge indexedb
          [...connection.objectStoreNames].forEach(item => {
            connection.deleteObjectStore(item);
          });

          // Create an objectStore to hold information about our customers. We're
          // going to use "ssn" as our key path because it's guaranteed to be
          // unique - or at least that's what I was told during the kickoff meeting.
          var objectStore = connection.createObjectStore('transactions', {
            keyPath: 'id',
          });
          if (!objectStore.indexNames.contains('account')) {
            objectStore.createIndex('account', 'account', { unique: false });
          }

          if (!objectStore.indexNames.contains('date')) {
            objectStore.createIndex('date', 'date', { unique: false });
          }

          if (!objectStore.indexNames.contains('category')) {
            objectStore.createIndex('category', 'category', { unique: false });
          }

          if (!objectStore.indexNames.contains('account')) {
            objectStore.createIndex('account', 'account', { unique: false });
          }

          objectStore = connection.createObjectStore('categories', {
            keyPath: 'id',
          });

          if (!objectStore.indexNames.contains('account')) {
            objectStore.createIndex('account', 'account', { unique: false });
          }
        };
        request.onblocked = function (event) {
          console.error(event);
          reject(event);
        };

        request.onerror = function (event) {
          console.error(request.error);
          reject(event);
        };
        request.onsuccess = function (event) {
          resolve(event.target.result);
        };
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  }

  reset() {}
}

let StorageInstance = new Storage();

export default StorageInstance;
