import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import AppActions from '../../actions/AppActions';

import { forgotPassword, resetPassword } from '../../services/auth.services.tsx';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import useRouteTitle from '../../hooks/useRouteTitle';
import PasswordField from '../forms/PasswordField';

import ModalLayoutComponent from '../layout/ModalLayoutComponent';

const styles = {
  actions: {
    textAlign: 'right',
  },
  urlField: {
    width: '100%',
    marginBottom: '16px',
  },
  loading: {
    margin: '8px 20px 0px 20px',
  },
  icon: {
    width: '40px',
    height: '40px',
    marginRight: 12,
    marginTop: -5,
    marginLeft: 20,
    verticalAlign: 'middle',
  },
};

export default function ForgottenPasswordForm(props) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const titleObject = useRouteTitle();

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState({});

  const [username, setUsername] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    if (props.step == 'FORGOTTEN_PASSWORD') {
      setEmail('');
      setDone(false);
      setError({});
    }
  }, [props.step]);

  const handleSaveChange = event => {
    if (event) {
      event.preventDefault();
    }

    setLoading(true);
    setError({});

    resetPassword(email)
      .then(() => {
        setLoading(false);
        setDone(true);
      })
      .catch(function (ex) {
        setLoading(false);
        setError({
          email: 'An error occured and prevented the email to be send.',
        });
      });
  };

  const handleCancel = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      navigate('/login');
    }
  };

  const handleUpdatePassword = async () => {
    try {
      await forgotPassword(username, tempPassword, newPassword).then(() => {
        navigate('/login');
      });
    } catch (error) {
      console.error(error);
      dispatch(AppActions.snackbar(error.message, 'Close', () => {}));
      setError({
        username: 'Invalid user email',
        tempPassword: 'Invalid temporary password',
        newPassword: 'Invalid new password',
      });
      return;
    }
  };

  return (
    <ModalLayoutComponent
      title={titleObject.title}
      content={
        <>
          <main>
            <Container sx={{ marginTop: 2 }}>
              {done ? (
                <>
                  <div style={{ paddingTop: 18 }}>
                    <Alert severity="success">
                      <AlertTitle>An email has been sent.</AlertTitle>
                      <p>We have sent you an email with a temporary password.</p>
                    </Alert>
                  </div>
                  <Container sx={{ marginTop: 2 }}>
                    <form onSubmit={handleUpdatePassword}>
                      {/* {isLoading && (
                          <>
                            <Box
                              sx={{
                                position: 'absolute',
                                background: 'rgba(255, 255, 255, 0.5)',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 999,
                              }}>
                              <CircularProgress size={80} />
                            </Box>
                          </>
                        )} */}
                      <Stack direction="column" spacing={2}>
                        <TextField label="Email" value={username} error={Boolean(error.username)} helperText={error.username} onChange={event => setUsername(event.target.value)} autoFocus={true} margin="normal" fullWidth disabled={false} />
                        <PasswordField label="Temporary Password" value={tempPassword} error={Boolean(error.password1)} helperText={error.tempPassword} onChange={event => setTempPassword(event.target.value)} fullWidth disabled={false} />
                        <PasswordField label="New Password" value={newPassword} error={Boolean(error.password2)} helperText={error.newPassword || `Password must be a minimum of 6 characters.`} onChange={event => setNewPassword(event.target.value)} fullWidth disabled={false} />
                        <br />
                        <Button variant="contained" disableElevation color="primary" onClick={() => handleUpdatePassword()} disabled={!tempPassword || !newPassword || !username}>
                          Update Password
                        </Button>
                      </Stack>
                      <input type="submit" style={{ display: 'none' }} />
                    </form>
                  </Container>
                </>
              ) : (
                <form onSubmit={handleSaveChange}>
                  <Stack direction="column" spacing={2}>
                    <p>We can send an email with a temporary link to reset your password.</p>
                    <TextField label="Email address" value={email} style={styles.urlField} disabled={loading} error={Boolean(error.email)} helperText={error.email} onChange={event => setEmail(event.target.value)} autoFocus={true} margin="normal" fullWidth />
                    <Button variant="contained" disableElevation color="primary" onClick={() => handleSaveChange()} disabled={!email || done}>
                      Send email
                    </Button>
                  </Stack>
                </form>
              )}
            </Container>
          </main>
        </>
      }
      footer={
        <>
          <Stack direction="row" spacing={2} style={{ justifyContent: 'space-between' }}>
            <Button color="inherit" onClick={() => handleCancel()}>
              Cancel
            </Button>
          </Stack>
        </>
      }
    />
  );
}
