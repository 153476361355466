import { useLocation } from 'react-router-dom';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import UserActions from '../../actions/UserActions';
import useRouteTitle from '../../hooks/useRouteTitle';
import PasswordField from '../forms/PasswordField';

import ModalLayoutComponent from '../layout/ModalLayoutComponent';

const CSS_TITLE = {
  fontSize: '2.3em',
};

export default function SignUpForm(props) {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    showPassword: false,
  });

  const dispatch = useDispatch();
  const location = useLocation();
  const titleObject = useRouteTitle();
  const server = useSelector(state => state.server);

  const [error, setError] = useState({});

  const [isLoading, setLoading] = useState(false);
  const [termsandconditions, setTermsandconditions] = useState(false);
  const [newsletter, setNewsletter] = useState(false);

  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  const signup = event => {
    if (event) {
      event.preventDefault();
    }

    if (password1 != password2) {
      setError({
        password2: "The two password fields didn't match.",
      });
      return;
    }
    setLoading(true);

    dispatch(UserActions.create(first_name, last_name, email, password1, password2, newsletter))
      .then(res => {
        // Singup succeed, we can login the user
        dispatch(UserActions.fetchToken(email, password1))
          .then(res => {
            // Switch to loading animation
            return dispatch(UserActions.login(res));
          })
          .then(res => {
            setLoading(false);
            handleCancel();
            navigate('/');
          })
          .catch(error => {
            if (error && error.response && error.response.data) {
              setLoading(false);
            } else {
              setLoading(false);
            }
          });
      })
      .catch(exception => {
        let error = {};

        if (exception.response.data.field) {
          error[exception.response.data.field] = exception.response.data.errorMsg;
        } else {
          Object.keys(exception.response.data).forEach(key => {
            error[key] = exception.response.data[key][0];
          });
        }

        setError(error);
        setLoading(false);
      });
  };

  const handleLogin = () => {
    props.onLogin();
  };

  const handleCancel = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      navigate('/get-started');
    }
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <ModalLayoutComponent
      title={'Sign up'}
      content={
        <>
          <Container>
            <Box
              sx={{
                flexGrow: 1,
                overflow: 'hidden',
                display: 'flex',
                height: '100%',
              }}
            >
              <form onSubmit={signup}>
                {isLoading && (
                  <>
                    <Box
                      sx={{
                        position: 'absolute',
                        background: 'rgba(255, 255, 255, 0.5)',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 999,
                      }}
                    >
                      <CircularProgress size={80} />
                    </Box>
                  </>
                )}
                <TextField label="First Name" value={first_name} error={Boolean(error.first_name)} helperText={error.first_name} onChange={event => setFirst_name(event.target.value)} margin="normal" fullWidth disabled={isLoading} />
                <TextField label="Last Name" value={last_name} error={Boolean(error.last_name)} helperText={error.last_name} onChange={event => setLast_name(event.target.value)} margin="normal" fullWidth disabled={isLoading} />
                <TextField label="Email" value={email} error={Boolean(error.email)} helperText={error.email} onChange={event => setEmail(event.target.value)} margin="normal" fullWidth disabled={isLoading} />
                <PasswordField label="Password" value={password1} error={Boolean(error.password1)} helperText={error.password1 || `Password must be a minimum of 6 characters.`} onChange={event => setPassword1(event.target.value)} fullWidth disabled={isLoading} />
                <PasswordField label="Repeat password" value={password2} error={Boolean(error.password2)} helperText={error.password2} onChange={event => setPassword2(event.target.value)} fullWidth disabled={isLoading} />
                <div>
                  <Checkbox checked={termsandconditions} onChange={event => setTermsandconditions(event.target.checked)} disabled={isLoading} />
                  <a className="hyperlink" href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                    Terms and Conditions
                  </a>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox checked={newsletter} onChange={event => setNewsletter(event.target.checked)} disabled={isLoading} />
                  <div style={{ whiteSpace: 'nowrap' }}>Stay up to date on the latest developments</div>
                </div>
                <br />
                <input type="submit" style={{ display: 'none' }} />
              </form>
            </Box>
          </Container>
        </>
      }
      footer={
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row-reverse',
              width: '100%',
            }}
          >
            <Button variant="contained" color="primary" disableElevation onClick={signup} disabled={isLoading || !termsandconditions}>
              Sign up
            </Button>
            <Button color="inherit" disableElevation onClick={() => handleCancel()}>
              Cancel
            </Button>
          </Box>
        </>
      }
      isLoading={isLoading}
    />
  );
}
