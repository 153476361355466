import axios from 'axios';
import md5 from 'blueimp-md5';
import Cookies from 'universal-cookie';
import encryption from '../encryption';

import { deleteUserAccount, loginUser, signupUser } from '../services/auth.services.tsx';
import { exportAllAccountDataToDB } from '../services/transactions.services.tsx';

import AppActions from './AppActions';
import CategoryActions from './CategoryActions';
import TransactionActions from './TransactionActions';

// Login stuff
import storage from '../storage';
import AccountsActions from './AccountsActions';
import ServerActions from './ServerActions';

import { SNACKBAR, UPDATE_ENCRYPTION, USER_CHANGE_THEME, USER_FETCH_PROFILE, USER_FETCH_TOKEN, USER_LOGIN, USER_LOGOUT, USER_LOGOUT_LOADING, USER_UPDATE_REQUEST } from '../constants';

var UserActions = {
  setTheme: (theme = 'light') => {
    if (theme !== 'light' && theme !== 'dark') {
      throw new Error('wrong args to UserActions.setTheme', theme);
    }
    return {
      type: USER_CHANGE_THEME,
      theme: theme,
    };
  },

  fetchToken: (email, password, recovering = false) => {
    return (dispatch, getState) => {
      return loginUser(email, password)
        .then(json => {
          const { userId, token } = json.data;
          const cipher = md5(password);
          const cookies = new Cookies();
          cookies.set('jwt_token', token);
          cookies.set('userId', userId);

          if (!recovering) {
            encryption.key(cipher);
            dispatch({
              type: USER_FETCH_TOKEN,
              token: userId,
              cipher,
            });
          }
          return Promise.resolve(json.data);
        })
        .catch(exception => {
          return Promise.reject(exception);
        });
    };
  },

  fetchProfile: profile => {
    return (dispatch, getState) => {
      try {
        const { email, firstName, lastName, profile: userProfile, userId, userName, epochtime } = profile;

        const USER_PROFILE = {
          username: userName,
          email,
          first_name: firstName,
          last_name: lastName,
          profile: userProfile,
          userId,
          last_login: epochtime,
        };
        dispatch({
          type: USER_FETCH_PROFILE,
          profile: USER_PROFILE,
        });
        return Promise.resolve(USER_PROFILE);
      } catch (error) {
        console.error(error);
        return Promise.reject(error);
      }
    };
  },

  logout: (force = false) => {
    return (dispatch, getState) => {
      return new Promise(resolve => {
        const cookies = new Cookies();
        cookies.remove('jwt_token');
        cookies.remove('userId');
        if (!force && getState().sync.counter > 0) {
          dispatch(
            AppActions.snackbar('You cannot logout because of unsynced modification.', 'Force', () => {
              dispatch(UserActions.logout(true)).then(() => {
                resolve();
              });
            })
          );
        } else {
          dispatch({ type: USER_LOGOUT_LOADING });

          encryption.reset();
          const remote_accounts = getState().accounts.remote.map(c => c.id);
          Promise.all([CategoryActions.flush(remote_accounts), TransactionActions.flush(remote_accounts)]).then(res => {
            dispatch({ type: USER_LOGOUT });
            resolve();
          });
        }
      });
    };
  },

  create: (first_name, last_name, email, password1, password2, newsletter) => {
    return (dispatch, getState) => {
      return signupUser(password1, email, first_name, last_name, newsletter)
        .then(response => {
          const userId = response.data.userId;
          const token = response.data.token;
          const account = {
            transactions: [],
            categories: [],
            account: {
              name: email,
              currency: 7,
              isLocal: false,
              id: userId,
            },
            server: {
              url: '',
              name: '',
            },
          };
          exportAllAccountDataToDB(account, token, userId).catch(function (exception) {
            return Promise.reject(exception);
          });
        })
        .catch(function (exception) {
          return Promise.reject(exception);
        });
    };
  },

  update: user => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/v1/rest-auth/user/',
          method: 'PATCH',
          headers: {
            Authorization: 'Token ' + getState().user.token,
          },
          data: user,
        })
          .then(json => {
            dispatch({
              type: USER_UPDATE_REQUEST,
              profile: json.data,
            });
            resolve();
          })
          .catch(exception => {
            console.error(exception);
            reject(exception.response.data);
          });
      });
    };
  },

  delete: password => {
    return (dispatch, getState) => {
      if (getState().user.cipher === md5(password)) {
        const email = getState().user.profile.email;
        return exportAllAccountDataToDB({})
          .then(() => {
            return deleteUserAccount(email)
              .then(res => console.log('Deleted Account: ', res))
              .catch(err => console.log('Error Deleting Account: ', err));
          })
          .catch(function (exception) {
            return Promise.reject(exception);
          });
      } else {
        return Promise.reject({
          password: 'Password incorrect',
        });
      }
    };
  },

  changeEmail: data => {
    return (dispatch, getState) => {
      return axios({
        url: '/api/v1/users/email',
        method: 'POST',
        headers: {
          Authorization: 'Token ' + getState().user.token,
        },
        data: {
          email: data.email,
        },
      })
        .then(json => {
          dispatch({
            type: USER_UPDATE_REQUEST,
            profile: json.data,
          });
        })
        .catch(error => {
          return Promise.reject(error.response.data);
        });
    };
  },

  changePassword: data => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        if (getState().sync.counter > 0) {
          dispatch({
            type: SNACKBAR,
            snackbar: {
              message: 'Password update failed because of unsynced modification. Sync then try again.',
            },
          });
          resolve();
        } else {
          axios({
            url: '/api/v1/rest-auth/password/change/',
            method: 'POST',
            headers: {
              Authorization: 'Token ' + getState().user.token,
            },
            data: data,
          })
            .then(response => {
              // Update user cipher
              const cipher = md5(data.new_password1);
              const old_cipher = getState().user.cipher;

              const { token } = getState().user;
              const { url } = getState().server;
              encryption.key(cipher);
              dispatch({
                type: UPDATE_ENCRYPTION,
                cipher,
              });

              dispatch(UserActions.updateServerEncryption(token, cipher, old_cipher))
                .then(_ => {
                  resolve();
                })
                .catch(_ => {
                  reject();
                });
            })
            .catch(error => {
              console.error(error);
              reject(error.response.data);
            });
        }
      });
    };
  },

  revokeToken: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        if (getState().sync.counter > 0) {
          dispatch({
            type: SNACKBAR,
            snackbar: {
              message: 'You cannot revoke token because of unsynced modification.',
            },
          });
          resolve();
        } else {
          axios({
            url: '/api/v1/users/token',
            method: 'DELETE',
            headers: {
              Authorization: 'Token ' + getState().user.token,
            },
          })
            .then(response => {
              dispatch(UserActions.logout(true));
              resolve();
            })
            .catch(exception => {
              console.error(exception);
              reject(exception);
            });
        }
      });
    };
  },

  updateServerEncryption: (token, newCipher, oldCipher) => {
    return (dispatch, getState) => {
      const url = getState().server.url;
      return Promise.all([
        AccountsActions.updateServerEncryption(url, token, newCipher, oldCipher),
        CategoryActions.updateServerEncryption(url, token, newCipher, oldCipher),
        TransactionActions.updateServerEncryption(url, token, newCipher, oldCipher),
        new Promise((resolve, reject) => {
          axios({
            url: '/api/v1/rest-auth/user/',
            method: 'get',
            headers: {
              Authorization: 'Token ' + token,
            },
          })
            .then(function (response) {
              resolve();
            })
            .catch(exception => {
              console.error(exception);
              reject(exception);
            });
        }),
      ]);
    };
  },

  login: userProfile => {
    return (dispatch, getState) => {
      const url = getState().server.url;

      return new Promise((resolve, reject) => {
        dispatch(ServerActions.connect(url))
          .then(() => {
            // connect storage to indexedDB
            return storage
              .connectIndexedDB()
              .then(() => {
                const user = getState().user;
                if (user.token && user.cipher) {
                  dispatch(UserActions.fetchProfile(userProfile))
                    .then(profile => {
                      if (profile) {
                        dispatch(AccountsActions.sync())
                          .then(accounts => {
                            // If after init user has no account, we redirect ot create one.
                            dispatch(AccountsActions.refreshAccount())
                              .then(() => {
                                dispatch({
                                  type: USER_LOGIN,
                                });
                                resolve();
                              })
                              .catch(exception => {
                                console.error(exception);
                                reject(exception);
                              });
                          })
                          .catch(exception => {
                            console.error(exception);
                            reject(exception);
                          });
                      } else {
                        reject('No Profile returned by fetchProfile');
                      }
                    })
                    .catch(exception => {
                      console.error(exception);
                      reject(exception);
                    });
                } else {
                  reject('no token and ni cipher or already profiled');
                }
              })
              .catch(exception => {
                reject('no token and ni cipher or already profiled');
              });
          })
          .catch(exception => {
            reject('no token and ni cipher or already profiled');
          });
      });
    };
  },

  pay: (token, product_id, coupon_code = undefined, description = 'No description') => {
    return (dispatch, getState) => {
      return axios({
        url: '/api/v1/payment',
        method: 'POST',
        data: {
          token: token,
          product_id,
          coupon_code,
          description,
        },
        headers: {
          Authorization: 'Token ' + getState().user.token,
        },
      });
    };
  },

  coupon: (product_id, coupon_code) => {
    return (dispatch, getState) => {
      return axios({
        url: `/api/v1/coupon/${product_id}/${coupon_code}`,
        method: 'GET',
        headers: {
          Authorization: 'Token ' + getState().user.token,
        },
      }).then(result => {
        return Promise.resolve({
          coupon_id: result.data.coupon_id,
          price: result.data.price,
        });
      });
    };
  },

  setBackupKey: (isBackedUp = true) => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/v1/rest-auth/user/',
          method: 'PATCH',
          headers: {
            Authorization: 'Token ' + getState().user.token,
          },
          data: {
            profile: {
              key_verified: isBackedUp,
            },
          },
        })
          .then(json => {
            dispatch({
              type: USER_UPDATE_REQUEST,
              profile: json.data,
            });
            resolve();
          })
          .catch(exception => {
            console.error(exception);
            reject(exception.response.data);
          });
      });
    };
  },

  toggleAutoSync: () => {},
};

export default UserActions;
