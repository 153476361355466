import axios from 'axios';
import Cookies from 'universal-cookie';
const URL = process.env.API_URL;
const PATH = '/transactions';
const url = `${URL}${PATH}`;
export const exportAllAccountDataToDB = (data, jwtToken, id) => {
  const cookies = new Cookies();
  const token = jwtToken || cookies.get('jwt_token');
  const userId = id || cookies.get('userId');

  return axios.put(url, data, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params: {
      userId,
    },
  });
};

export const fetchAllAccountData = () => {
  const cookies = new Cookies();
  const token = cookies.get('jwt_token');
  const userId = cookies.get('userId');
  return axios.get(url, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    params: {
      userId,
    },
  });
};
