import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Container from '@mui/material/Container';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
Stack;

import ModalLayoutComponent from '../layout/ModalLayoutComponent';

const terms_and_conditions = `<div>
  <p>Webudget Terms of Use Agreement</p>
  <p>Date of Last Revision: October 28, 2024</p>
  <p>Webudget is an online service intended to help individuals to manage their budgets.</p>
  <p>By accessing or using the Webudget website, located at www.webudget.io, or any successor websites (the “Services”), or by otherwise accessing any portion of Webudget’s programs you (“you”) agree to be bound by this Terms of Use Agreement (this “Agreement”) and any agreements, policies and other documents incorporated by reference herein. In addition, if you wish to become a registered user of the Services (“User”), please read this Terms of Use Agreement and indicate your acceptance by clicking the I agree checkbox on Webudget’s Registration Form.</p>
  <p>We reserve the right, at our sole discretion, to change, modify, add, or delete portions of this Terms of Use at any time without further notice. If we do this, we will post the changes to this Terms of Use at www.webudget.io/terms-of-use and will indicate at the top of that page the date these terms were last revised. Your continued use of the Services after any such change constitutes your acceptance of the new Terms of Use. If you do not agree to this or any future Terms of Use, you may not use or access (or continue to use or access) the Services. It is your responsibility to regularly check the Services to determine if there have been changes to this Terms of Use and to review such changes. Should you have any questions concerning this Agreement, need technical support, or have complaints or claims with respect to the Services, you may contact support@webudget.io.</p>
  <p>You may receive a written copy of this Agreement by emailing us at: support@webudget.io, Subject: Webudget Terms of Use Agreement.</p>
  <p><strong>1. TERM.</strong><br>
  This Agreement will remain in full force and effect while you use the Services. The Services are intended solely for users who are eighteen (18) years of age or older. Any registration by, use of or access to the Services by anyone under eighteen (18) is unauthorized, unlicensed, and in violation of these Terms of Use. The use of the “Webudget Free” plan under the Services is currently free of charge. To the extent we decide in our sole and absolute discretion to charge for the Services, we will notify you of the same, and you agree to either pay the appropriate fees in order to obtain such services or to discontinue use of the Services.</p>
  <p><strong>2. REGISTRATION DATA; ACCOUNT SECURITY.</strong><br>
  You agree to (a) provide accurate, current, and complete information on any registration forms on the Services (“Registration Data”); (b) maintain the security of your password and identification; (c) maintain and promptly update the Registration Data, and any other information you provide to us, to keep it accurate, current, and complete; and (d) be fully responsible for all use of your account and for any actions that take place using your account. You consent to receiving communications and notices from Webudget at the email address you provide in your Registration Data or otherwise elect in your account settings.</p>
  <p><strong>3. NON COMMERCIAL USE.</strong><br>
  The Services are for the use by Users in their individual capacities as Webudget Users only, may not be used in connection with any commercial or other endeavors, and may not be duplicated, resold, or assigned by you. Organizations, companies, and/or businesses may not use the Services for any purpose other than those contemplated in this Terms of Use or Privacy Policy. Illegal and/or unauthorized uses by you of the Services, including collecting user names and/or email addresses of Users by electronic or other means for any reason, including — but not limited to, the purpose of sending unsolicited emails and unauthorized framing of or linking to the Services — is a violation of this Agreement, and appropriate legal action may be taken, including, without limitation, civil, criminal, and injunctive redress.</p>
  <p><strong>4. PROPRIETARY RIGHTS IN THE SERVICES; LIMITED LICENSE.</strong><br>
  All content on the Services and their selection and arrangement, but excluding User Content (the “Services Content”), are the proprietary property of Webudget or its licensors, with all rights reserved. No Services Content may be modified, copied, distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted, or sold in any form or by any means, in whole or in part, without our prior written permission. Subject to these Terms of Use, you are granted a limited non-transferable license to access and use the Services and the Services Content on any device you legally own or control subject to any terms of use associated with that device, and to download or print a copy of any properly-accessed portion of the Services Content solely for your personal, non-commercial use, provided that you keep all copyright or other proprietary notices intact. Such license does not permit use of any data mining, robots, scraping, or similar data gathering or extraction methods. You further agree not to access (or attempt to access) any of the Services by any means other than through the interfaces provided by Webudget. You may not rent, lease, lend, sell, redistribute, or sublicense any portion of the Services. Unless explicitly stated herein, nothing in these Terms of Use will be construed as conferring any license to intellectual property rights, whether by estoppel, implication or otherwise. We may revoke this license at any time without notice and with or without cause.</p>
  <p>Webudget reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, any of the Services or any features within the Services at Webudget’s sole discretion and without prior notice to you.</p>
  <p>Webudget shall not be liable to you or to any third party for any modification, price change, suspension, or discontinuance of the Services.</p>
  <p><strong>5. TRADEMARKS.</strong><br>
  “Webudget,” the “Webudget” logo and other Webudget graphics, logos, designs, page headers, button icons, scripts, and service names are our trademarks or trade dress in the U.S. and/or other countries. Our trademarks and trade dress may not be used, including as part of trademarks and/or as part of domain names, in connection with any product or service in any manner that is likely to cause confusion and may not be copied, imitated, or used, in whole or in part, without our prior written permission.</p>
  <p><strong>6. USER CONTENT POSTED ON THE SERVICES.</strong><br>
  Your account, transaction, and envelope information, and other information that you may from time-to-time provide, whether directly or indirectly such as through a financial data aggregation system, to the Services (collectively, “User Content”) belong to you and will be used solely to provide the Services to you in ways consistent with this Agreement. You retain copyright and any other rights you already hold in the User Content. By providing the User Content, you automatically grant and you represent and warrant that you own or have the necessary license, rights, consents, and permissions to grant, to Webudget a perpetual, irrevocable, worldwide, royalty-free and non-exclusive license to use, reproduce, display, adapt, modify, distribute, perform, and publish within the Services and for the purpose of providing the Services any content which you provide to the Services. Your account, transaction, and envelope information will only be published in a manner that is accessible through your account.</p>
  <p>If you post User Content that is intended and understood to be publicly accessible, you grant Webudget a perpetual, irrevocable, worldwide, royalty-free and non-exclusive license to use, reproduce, display, adapt, modify, distribute, perform, publish, and create derivative works of such content with no further accounting to you as long as attribution to you as author is preserved.</p>
  <p>Other than the limited license set forth above, Webudget acknowledges and agrees that it obtains no right, title or interest from you (or your licensors) under this Agreement in or to any User Content that you provide to the Services. You agree that you are responsible for protecting and enforcing your intellectual property rights and that Webudget has no obligation to do so on your behalf.</p>
  <p>While Webudget will endeavor to maintain availability and access to your User Content, you understand and agree that You are solely responsible at your sole cost and expense for creating backup copies and replacing any User Content you post on the Services or provide to us.</p>
  <p>You agree to the following:</p>
  <ul>
    <li>You will not use the Services to do anything unlawful, misleading, malicious, or discriminatory.</li>
    <li>You will not facilitate or encourage any violations of this Term of Use.</li>
    <li>You will not enter anyone’s social security, driver’s license, passport, credit card, debit card, bank account, phone numbers, or other similar content such as a postal address, unique personal identifier, online identifier, internet protocol address, or similar identifiers, including your own, on the Services. Further, you will not enter your full name or alias on the Services, nor will you enter any email address outside of fields that request your email address, such as for registration or login.</li>
    <li>You will not use Webudget if you are located in a country embargoed by the United States, or are on the United States Treasury Department’s list of Specially Designated Nationals. By using the Services, you represent and warrant that (i) you are not located in a country that is subject to U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.</li>
  </ul>
  <p><strong>7. PRIVACY.</strong><br>
  Use of the Services is also governed by our Privacy Policy available at www.webudget.io/privacy-policy/.</p>
  <p><strong>8. Free Plan</strong><br>
  Webudget provides Services for free. Webudget may in the future add a new subscription plan that charges a subscription fee for the duration of your use of the Services.</p>
  <p><strong>9. TERMS AND TERMINATION</strong><br>
  All Services are made available through the Webudget website until you disable a Service. You may disable any Service at any time by accessing your account page and following the instructions for disabling a Service. Disabling any Service may cause the loss of content, features, or capacity of your account. Canceling your account will cause the immediate loss of all Content, features, and capacity of your account. This information cannot be recovered and Webudget does not accept any liability for such loss.</p>
  <p>Webudget, in its sole discretion, may immediately terminate this Agreement, your account and all current or future use of the Services, for any reason at any time. Termination of your account includes removal of your access to all Services, and the forfeiture and relinquishment of all User Content in your account. Webudget reserves the right to refuse services to anyone for any reason at any time.</p>
  <p><strong>10. INTELLECTUAL PROPERTY RIGHTS COMPLAINTS.</strong><br>
  When we receive proper notification of alleged copyright infringement, we will investigate and may remove or disable access to the allegedly infringing material and may terminate, in our sole discretion, the accounts of repeat infringers in accordance with the Digital Millennium Copyright Act and other applicable law. We may also, at our sole discretion, limit access to the Services and/or terminate the memberships of any Users who infringe any intellectual property rights of others, whether or not there is any repeat infringement. If you believe that any material on the Services infringes upon any copyright which you own or control, you may contact us. Webudget will assess the material and may remove the material from public viewing.</p>
  <p><strong>11. THIRD PARTY SERVICES, WEBSITES, AND CONTENT.</strong><br>
  Use of the Services may require Internet access. User agrees to accept any additional terms of service of any third party service required to access the Services. The Services contain or may contain in the future (or you may be sent through the Services) links to other web sites (“Third Party Sites”) as well as articles, photographs, text, items and other content belonging to or originating from third parties (the “Third Party Components”). Such Third Party Sites and Third Party Components are not investigated, monitored or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for the acts or omissions of any Third Party Sites or Third Party Components. If you decide to leave the Services and access the Third Party Sites or to use or install any Third Party Components, you do so at your own risk and our terms and policies no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any Third Party Services to which you navigate from the Services or relating to any Third Party Components.</p>
  <p><strong>12. VIRAL DISTRIBUTION WIDGETS.</strong><br>
  In order to promote the Services, we may supply widgets that allow you to incorporate your content in Third Party Sites. If you choose to use such widgets, you give us permission to export to Third Party Sites some features of your profile. Such features that may be exported to Third Party Sites include, but are not limited to, your name and picture. Widgets we supply will clearly indicate what information they will export to Third Party Sites.</p>
  <p><strong>13. DISCLAIMER OF WARRANTIES.</strong><br>
  YOUR USE OF THE SERVICES ARE AT YOUR SOLE RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WEBUDGET AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, LICENSORS AND USERS EXPRESSLY DISCLAIM TO YOU ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>
  <p>WEBUDGET AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, LICENSORS, AND USERS MAKE NO WARRANTY TO YOU (i) THAT THE SERVICES WILL MEET YOUR REQUIREMENTS; (ii) THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (iii) THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE, RELIABLE, OR GENERATE BENEFIT (INCLUDING WITHOUT LIMITATION ECONOMIC BENEFIT) TO YOU; AND (iv) REGARDING THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE WEBSITE.</p>
  <p>ANY MATERIAL OR INFORMATION DOWNLOADED, OTHERWISE OBTAINED, OR USED THROUGH THE USE OF THE SERVICES IS MADE AVAILABLE AT YOUR OWN RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR COMPUTER SYSTEM, INTERNET ACCESS, DOWNLOAD, OR DISPLAY DEVICE, OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF ANY SUCH MATERIAL OR INFORMATION.</p>
  <p>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM WEBUDGET, OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY WARRANTY AND ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</p>
  <p><strong>14. LIMITATION ON LIABILITY.</strong><br>
  YOU EXPRESSLY UNDERSTAND AND AGREE THAT WEBUDGET AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, LICENSORS, AND USERS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, COSTS, LOSSES, OR EXPENSES, INCLUDING, BUT NOT LIMITED TO, THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF WEBUDGET HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (a) THE USE OR THE INABILITY TO USE THE SERVICES; (b) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (c) ANY OTHER MATTER RELATING TO THE SERVICES.</p>
  <p>IN NO EVENT SHALL THE AGGREGATE LIABILITY OF WEBUDGET ARISING OUT OF OR RELATING TO THE USE OF OR INABILITY TO USE THE SERVICES, EXCEED THE EQUIVALENT OF ONE (1) PERIOD OF SUBSCRIPTION FEES ACTUALLY PAID BY YOU.</p>
  <p><strong>15. EXCLUSIONS AND LIMITATIONS.</strong><br>
  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS OF SECTIONS 13 AND 14 MAY NOT APPLY TO YOU TO THE EXTENT SO PROHIBITED BY LAW.</p>
  <p><strong>16. DISPUTES.</strong><br>
  If there is any dispute about or involving the Services, you agree that the dispute will be governed by the laws of the State of New York without regard to its conflict of law provisions and will be resolved in a state or federal court located in New York, New York. You agree to personal jurisdiction by and venue in the state and federal courts of the State of New York, New York City.</p>
  <p><strong>17. INDEMNITY.</strong><br>
  You agree to indemnify and hold Webudget, its subsidiaries and affiliates, and each of their directors, officers, agents, contractors, partners, and employees, harmless from any loss, liability, claim, or demand, including reasonable attorney’s fees, made by any third party due to or arising out of your use of the Services, any User Content you post on or through the Services and/or arising from a breach of this Agreement.</p>
  <p><strong>18. OTHER.</strong><br>
This Agreement (together with any agreements, policies, or other documents incorporated by reference herein), accepted upon use of the Services and further affirmed by becoming a registered User, contains the entire agreement between you and Webudget regarding the use of the Services and/or the programs offered by Webudget. If any provision of this Agreement is held invalid, the remainder of this Agreement shall continue in full force and effect. Our failure to exercise or enforce any right or provision of these Terms of Use will not constitute a waiver of such right or provision in that or any other instance. If any provision of these Terms of Use is held invalid, the remainder of these Terms of Use will continue in full force and effect. If any provision of these Terms of Use is deemed unlawful, void, or for any reason unenforceable, then that provision will be deemed severable from these Terms of Use and will not affect the validity and enforceability of any remaining provisions. These Terms of Use are in the English language only, which language will be controlling in all respects, and all versions hereof in any other language will not be binding on the parties hereto. All communications and notices to be made or given pursuant to these Terms of Use will be in the English language. 


  <p><strong>Contact Information</strong><br>
Any questions, complaints, or claims with respect to the Services can be directed to the following email address: support@webudget.io </div>`;

export default function TermsAndConditions(props) {
  const server = useSelector(state => state.server);
  const navigate = useNavigate();

  const handleCancel = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      navigate('/get-started');
    }
  };

  return (
    <ModalLayoutComponent
      title={'Terms and conditions'}
      content={
        <>
          <div>
            <Container
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                paddingBottom: 80,
              }}
            >
              <div
                style={{
                  overflow: 'auto',
                  margin: '0px 0',
                  padding: '0px 6px 5px 0px',
                  textAlign: 'justify',
                  fontSize: '0.9rem',
                }}
                dangerouslySetInnerHTML={{
                  __html: terms_and_conditions,
                }}
              />
            </Container>
          </div>
        </>
      }
      footer={
        <>
          <Stack>
            <Button color="inherit" onClick={() => handleCancel()}>
              Cancel
            </Button>
          </Stack>
        </>
      }
    />
  );
}
