import { Navigate } from 'react-router-dom';

// Get started section
import GetStarted from './components/launcher/GetStarted';
import HowToInstall from './components/launcher/HowToInstall';
import ImportAccount from './components/launcher/ImportAccount';
import LoginForm from './components/launcher/LoginForm';
import Layout from './components/Layout';
import ForgottenPasswordForm from './components/login/ForgottenPasswordForm';
import SignUpForm from './components/login/SignUpForm';
import TermsAndConditions from './components/login/TermsAndConditions';
import ResetPassword from './components/ResetPassword';

// Main categories
import Categories from './components/Categories';
import { Category } from './components/categories/Category';
import Dashboard from './components/Dashboard';
import Report from './components/Report';
import Reset from './components/Reset';
import Search from './components/Search';
import Settings from './components/Settings';
import Transactions from './components/Transactions';

import ForceBugReport from './components/errors/ForceBugReport';
import DevelopmentSettings from './components/settings/DevelopmentSettings';
import ProfileSettings from './components/settings/ProfileSettings';
import ThemeSettings from './components/settings/ThemeSettings';

import GuardHasAccount from './components/guards/GuardHasAccount';
import GuardHasNoAccount from './components/guards/GuardHasNoAccount';
import GuardIsDeveloper from './components/guards/GuardIsDeveloper';

// year
const year = new Date().getFullYear();
// month
const month = new Date().getMonth() + 1;

import BugReport from './components/errors/BugReport';
import ErrorBoundary from './components/errors/ErrorBoundary';

const routes = [
  {
    element: (
      <ErrorBoundary fallback={<BugReport />}>
        <Layout />
      </ErrorBoundary>
    ),
    title: 'Root',
    children: [
      {
        path: '/',
        title: 'WeBudget',
        element: (
          <GuardHasNoAccount>
            <GetStarted />
          </GuardHasNoAccount>
        ),
      },
      {
        path: '/how-to-install',
        title: 'Install the app',
        back: '/',
        element: (
          <GuardHasNoAccount>
            <HowToInstall />
          </GuardHasNoAccount>
        ),
      },
      {
        path: 'create-account',
        title: 'Create Account',
        back: '/',
        element: (
          <GuardHasNoAccount>
            <SignUpForm />
          </GuardHasNoAccount>
        ),
      },
      {
        path: 'import-account',
        title: 'Import a .json file',
        back: '/login',
        element: (
          <GuardHasNoAccount>
            <ImportAccount />
          </GuardHasNoAccount>
        ),
      },
      {
        path: 'login',
        title: 'Welcome back',
        back: '/',
        element: (
          <GuardHasNoAccount>
            <LoginForm />
          </GuardHasNoAccount>
        ),
      },
      {
        path: 'password/reset',
        title: 'Forgotten password',
        back: '/login',
        element: (
          <GuardHasNoAccount>
            <ForgottenPasswordForm />
          </GuardHasNoAccount>
        ),
      },
      {
        path: 'resetpassword',
        title: 'Forgotten password',
        back: '/login',
        element: <ResetPassword />,
      },
      {
        path: 'terms-and-conditions',
        title: 'Terms and conditions',
        back: '/get-started',
        element: <TermsAndConditions />,
      },
      // HAS ACCOUNT
      {
        path: 'dashboard',
        title: 'Dashboard',
        element: (
          <GuardHasAccount>
            <Dashboard />
          </GuardHasAccount>
        ),
      },
      {
        path: 'report',
        title: 'Report',
        element: (
          <GuardHasAccount>
            <Report />
          </GuardHasAccount>
        ),
      },
      {
        path: 'transactions',
        element: (
          <GuardHasAccount>
            <Navigate replace to={`/transactions/${year}/${month}`} />
          </GuardHasAccount>
        ),
      },
      {
        path: 'transactions/:year/:month',
        element: (
          <GuardHasAccount>
            <Transactions />
          </GuardHasAccount>
        ),
      },
      {
        path: 'transactions/:year/:month/:day',
        element: (
          <GuardHasAccount>
            <Transactions />
          </GuardHasAccount>
        ),
      },
      {
        path: 'categories',
        title: 'Categories',
        element: (
          <GuardHasAccount>
            <Categories />
          </GuardHasAccount>
        ),
        children: [
          {
            path: ':id',
            back: '/categories',
            element: (
              <GuardHasAccount>
                <Category />
              </GuardHasAccount>
            ),
          },
        ],
      },
      {
        path: 'search',
        title: 'Search',
        element: (
          <GuardHasAccount>
            <Search />
          </GuardHasAccount>
        ),
      },
      {
        path: 'settings',
        title: 'Settings',
        element: (
          <GuardHasAccount>
            <Settings />
          </GuardHasAccount>
        ),
        children: [
          {
            path: 'profile',
            title: 'Profile',
            back: '/settings',
            element: (
              <GuardHasAccount>
                <ProfileSettings />
              </GuardHasAccount>
            ),
          },
          // {
          //   path: 'import/export/',
          //   back: '/settings',
          //   element: (
          //     <GuardHasAccount>
          //       <ImportExportSettings />
          //     </GuardHasAccount>
          //   ),
          // },
          {
            path: 'theme',
            title: 'Theme',
            back: '/settings',
            element: (
              <GuardHasAccount>
                <ThemeSettings />
              </GuardHasAccount>
            ),
          },
          {
            path: 'development',
            title: 'Development',
            back: '/settings',
            element: (
              <GuardHasAccount>
                <GuardIsDeveloper>
                  <DevelopmentSettings />
                </GuardIsDeveloper>
              </GuardHasAccount>
            ),
          },
        ],
      },
      {
        path: 'crash',
        title: 'Crash report',
        element: <ForceBugReport />,
      },
      {
        path: 'reset',
        title: 'Reset',
        element: <Reset />,
      },
      {
        path: '*',
        element: <Navigate replace to={`/`} />,
      },
    ],
  },
];

export default routes;
