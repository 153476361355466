/**
 * In this file, we create a React component
 * which incorporates components provided by Material-UI.
 */
import { useDispatch, useSelector } from 'react-redux';

import AppActions from '../../actions/AppActions';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import Divider from '@mui/material/Divider';

import DeleteForever from '@mui/icons-material/DeleteForever';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import DeleteUserForm from '../settings/profile/DeleteUserForm';
import EmailForm from '../settings/profile/EmailForm';
import PasswordForm from '../settings/profile/PasswordForm';
import NameForm from './profile/NameForm';

export default function ProfileSettings(props) {
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();

  const _editPassword = () => {
    dispatch(AppActions.openModal(<PasswordForm onSubmit={() => dispatch(AppActions.closeModal())} onClose={() => dispatch(AppActions.closeModal())} />));
  };

  const _editFirstName = () => {
    dispatch(AppActions.openModal(<NameForm isFirstName onSubmit={() => dispatch(AppActions.closeModal())} onClose={() => dispatch(AppActions.closeModal())} />));
  };
  const _editLastName = () => {
    dispatch(AppActions.openModal(<NameForm onSubmit={() => dispatch(AppActions.closeModal())} onClose={() => dispatch(AppActions.closeModal())} />));
  };

  const _editMail = () => {
    dispatch(AppActions.openModal(<EmailForm onSubmit={() => dispatch(AppActions.closeModal())} onClose={() => dispatch(AppActions.closeModal())} />));
  };

  const _deleteUser = () => {
    dispatch(AppActions.openModal(<DeleteUserForm onSubmit={() => dispatch(AppActions.closeModal())} onClose={() => dispatch(AppActions.closeModal())} />));
  };

  return (
    <>
      {profile && (
        <List className="wrapperMobile">
          <ListItem button onClick={_editMail}>
            <ListItemText primary="Email" secondary={profile.email} />
            <KeyboardArrowRight />
          </ListItem>
          <ListItem button onClick={_editFirstName}>
            <ListItemText primary="First Name" secondary={profile.first_name} />
            <KeyboardArrowRight />
          </ListItem>
          <ListItem button onClick={_editLastName}>
            <ListItemText primary="Last Name" secondary={profile.last_name} />
            <KeyboardArrowRight />
          </ListItem>
          <Divider />
          <ListItem button onClick={_editPassword}>
            <ListItemText primary="Change password" secondary="Do not neglect security" />
            <KeyboardArrowRight />
          </ListItem>
          <ListItem button onClick={_deleteUser}>
            <ListItemText primary="Delete my user account" secondary="Permanently delete all data from this server" primaryTypographyProps={{ color: 'error' }} />
            <DeleteForever color="error" />
          </ListItem>
        </List>
      )}
    </>
  );
}
