import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

import { thunk } from 'redux-thunk';

import rootReducer from './reducers';

const logger = createLogger({
  collapsed: true,
  duration: true,
  timestamp: true,
  level: 'log',
  diff: false,
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['state', 'transactions'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(persistedReducer, applyMiddleware(thunk, logger));
let persistor = persistStore(store);

export { persistor, store };
